.overlay-spinner {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0.8);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 1;
    z-index: 2;
    pointer-events: all;
}

.overlay-spinner > div {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    font-size: 15px;
    color: rgba(0,0,0,0.6);
    -webkit-transform: translateY(-52%);
    transform: translateY(-52%);
    -webkit-transition: all .3s cubic-bezier(.25,.46,.45,.94);
    transition: all .3s cubic-bezier(.25,.46,.45,.94);
}