/*@import '~antd/dist/antd.css';*/

html h1, html h2, html h3, html h4, html h5, html h6, html a, html p, html li, input, textarea, span, div, html, body, html a {
    margin-bottom: 0px;
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
}

/*.san-topbar {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    z-index: 1000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0px 31px 0px 0;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

    position: fixed;
    width: 100%;
    height: 54px;
}*/

.san-topbar {
    background: #40a9ff;
    padding: 0;
    /*padding: 8px 8px;*/
    /*height: 35px;*/
}

.san-topbar.collapsed .san-logo-wrapper {
    width: 80px;
    padding: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.san-topbar .san-left {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.san-logo-wrapper {
    width: 150px;
    text-align: center;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 0;
    border-right: 1px solid rgb(237, 237, 237);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    float: left;
    background: white;
}

.ant-drawer-body .san-logo-wrapper {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid rgb(237, 237, 237);
}

.san-logo-wrapper h3 a {
    font-size: 21px;
    font-weight: 300;
    line-height: 64px;
    letter-spacing: 3px;
    text-transform: uppercase;
    /*  color: rgb(249, 249, 249);*/
    display: block;
    text-decoration: none;
}

.trigger {
    font-size: 19px;
    line-height: 64px;
    padding: 14px 15px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.san-content {
    padding: 0;
    flex-shrink: 0;
    background: rgb(241, 243, 246);
    position: relative;
}

.san-dashboard-menu {
    background-color: #40a9ff;
    line-height: 63px !important;
}

.ant-drawer-body .san-dashboard-menu {
    background-color: white;
    line-height: 63px !important;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    color: white;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: white;
    border-bottom: 2px solid white;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title:hover,
.ant-menu-horizontal > .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: white !important;
}

/*.ant-menu-item:hover, .ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
    color: white;
}*/

/*.san-dashboard-menu {
    background: transparent;
    height: calc(100vh - 70px);
    overflow: hidden;
}*/

/*
.san-dashboard-menu:hover {
    overflow: hidden auto;
}
*/

/*.table {
  width: 100%!important;
  max-width: 100%!important;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid black;
}

.table {
  border-collapse: collapse !important;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 3px;
  line-height: 1.42857143;
  vertical-align: top;
  border: 1px solid #000000;
}*/


.table {
    width: 100%;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
    border: 0;
}

.table-bordered-black {
    border: 1px solid #000 !important;
}

.table-bordered-black td, .table-bordered-black th {
    border: 1px solid #000 !important;
}

.ant-table-small > .ant-table-content .ant-table-header {
    background-color: #fafafa;
}

/*.ant-table .ant-table-thead > tr > th {*/
/*  padding: 2px 5px!important;*/
/*}*/

.ant-table .ant-table-tbody > tr > td {
    padding: 4px 5px !important;
}

.customer-select-modal-table tr:hover {
    cursor: pointer;
}

.hide-table-placeholder .ant-table-placeholder {
    display: none;
}

.form-sm .ant-form-item-label {
    margin: 0;
    padding: 0;
}

.form-sm.ant-form-item {
    margin: 0;
    padding: 0;
}

.ant-table-hover tr:hover {
    cursor: pointer;
}

.header-menu {
    line-height: 52px;
    border-bottom: 0;
}

.header-menu .ant-menu-submenu-title {
    padding: 0;
}

.voucher-create-bottom-form {
    padding: 8px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.voucher-create-bottom-form .ant-form-item {
    display: flex;
}

.voucher-create-bottom-form .ant-form-item-control-wrapper {
    flex: 1;
}

.text-danger {
    color: #ff4d4f;
}


.cursor-pointer:hover {
    cursor: pointer;
}

.sa-header {
    background: white;
    padding: 8px 8px;
    height: 35px;
}

.sa-table-sm .ant-table-body > table > .ant-table-tbody > tr > td {
    font-size: 13px;
}

.sa-table-sm .ant-table-tbody > tr > td {
    font-size: 13px;
}

.sa-table-sm .ant-table-thead > tr > th,
.sa-table-sm .ant-table-small tfoot > tr > td {
    font-size: 12px;
    padding: 1px 3px !important;
}

.sa-table-sm .ant-table-tbody > tr > td {
    border-bottom: 1px solid #dee2e6;
}

.sa-table-sm .ant-table-body > table > .ant-table-tbody > tr > td,
.sa-table-sm .ant-table-small tfoot > tr > td {
    padding: 1px 2px !important;
}

.sa-table-sm .ant-table.ant-table-bordered thead > tr > th,
.sa-table-sm .ant-table.ant-table-bordered tbody > tr > td,
.sa-table-sm .ant-table.ant-table-bordered tfoot > tr > th,
.sa-table-sm .ant-table.ant-table-bordered tfoot > tr > td {
    border-right: 1px solid #dee2e6;
}

.sa-description-small.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.sa-description-small.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
    padding: 2px 6px;
}

.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.50);
}

.bg-light-cyan {
    background: #CCFFFF;
    color: #000;
}

.voucher-create-form .account-name-input {
    border: 0;
}

.voucher-create-form .account-name-input:focus,
.voucher-create-form .ant-input-focused {
    border: 0;
    box-shadow: none;
}

.voucher-create-form .debit-amount-input {
    border: 0;
}

.voucher-create-form .debit-amount-input:focus,
.voucher-create-form .ant-input-number-focused {
    border: 0;
    box-shadow: none;
}

.voucher-create-form .debit-amount-input:hover {
    border: 0;
    box-shadow: none;
}

.voucher-create-form .credit-amount-input {
    border: 0;
}

.voucher-create-form .credit-amount-input:focus,
.voucher-create-form .ant-input-number-focused {
    border: 0;
    box-shadow: none;
}

.voucher-create-form .credit-amount-input:hover {
    border: 0;
    box-shadow: none;
}


.voucher-create-form .ant-input-number-disabled .ant-input-number-input {
    background: white;
}


tfoot.ant-table-summary th, tfoot.ant-table-summary td {
    background: #fafafa;
    font-weight: bold;
}


.san-form-item-required .ant-form-item-label > label::before {
    display: inline-block;
    margin-right: 4px;
    color: red;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    font-weight: bold;
}


.bg-summary-light {
    background: #fafafa;
}

.text-bold {
    font-weight: bold;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

[data-theme='compact'] .ant-advanced-search-form,
.ant-advanced-search-form {
    padding: 10px;
    /*background: #fbfbfb;*/
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='compact'] .ant-advanced-search-form .ant-form-item,
.ant-advanced-search-form .ant-form-item {
    display: flex;
}

[data-theme='compact'] .ant-advanced-search-form .ant-form-item-control-wrapper,
.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

.ant-form {
    max-width: none;
}

.search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}

[data-theme="dark"] .ant-advanced-search-form {
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid #434343;
    padding: 24px;
    border-radius: 2px;
}

[data-theme="dark"] .search-result-list {
    border: 1px dashed #434343;
    background: rgba(255, 255, 255, 0.04);
}

.ant-advanced-search-form .ant-form-item {
    margin-bottom: 2px;
}

.ant-advanced-search-form.ant-form-vertical .ant-form-item-label {
    padding: 0;
}

.office-info-box {
    padding: 0 5px 0 5px !important;
    border: 1px solid #91d5ff;
    /*background-color: #e6f7ff;*/
}


.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
    display: none;
}

.virtual-table-cell {
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    background: #FFF;
}

/*.ant-table-measure-row {*/
/*    display: none;*/
/*}*/

.ant-drawer-top.ant-drawer-open, .ant-drawer-bottom.ant-drawer-open {
    transition: none!important;
}

.ant-drawer > *,
.ant-drawer.ant-drawer-open .ant-drawer-mask {
    transition: none!important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin: -1px 10px 0;
    padding: 0;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 20px !important;
    line-height: 20px !important;
    padding: 0 8px;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
    color: white;
}
