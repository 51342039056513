.spinner-wrap {
    text-align: center;
    padding: 16px;
}

.spinner {
    display: inline-block;
    border: solid 2px transparent;
    vertical-align: middle;
    margin: 0 auto;

    -webkit-animation: spinner 400ms linear infinite;
    -moz-animation: spinner 400ms linear infinite;
    -ms-animation: spinner 400ms linear infinite;
    -o-animation: spinner 400ms linear infinite;
    animation: spinner 400ms linear infinite;
}

.spinner.spinner-white {
    border-top-color: white;
    border-left-color: white;
}

.spinner.spinner-blue {
    border-top-color: #2780e3;
    border-left-color: #2780e3;
}

.spinner.spinner-jbs {
    border-top-color: #C10027;
    border-left-color: #C10027;
}

.spinner.spinner-xs {
    width: 15px;
    height: 15px;
    border-radius: 10px;
}

.spinner.spinner-sm {
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

.spinner.spinner-md {
    width: 24px;
    height: 24px;
    border-radius: 20px;
}

.spinner.spinner-overlay {
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border-width: 2px;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes spinner {
    0% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
